import React, { useRef } from "react";
import { jsPDF } from "jspdf";
import * as htmlToImage from "html-to-image";
import ecLogo from "../../assets/image/ec.png";

function NIDVerification({ data }) {
  const {
    name,
    nameEn,
    gender,
    father,
    mother,
    spouse,
    nationalId,
    dateOfBirth,
    photo,
    pin,
    occupation,
    presentAddress,
    permanentAddress,
  } = data || {};

  const printRef = useRef();

  const handleDownloadOrPrintDocument = (action) => {
    const pdfWidth = 210; // A4 width in mm
    const pdfHeight = 297; // A4 height in mm

    const documentContent = printRef.current;

    // Set the dimensions of the div to A4 size
    documentContent.style.width = `${pdfWidth}mm`;
    documentContent.style.height = `${pdfHeight}mm`;

    htmlToImage.toPng(documentContent, { quality: 1.0 }).then((dataUrl) => {
      const pdf = new jsPDF({
        unit: "mm",
        format: [pdfWidth, pdfHeight],
      });

      const imgProps = pdf.getImageProperties(dataUrl);
      const aspectRatio = imgProps.width / imgProps.height;

      // Scale the image while maintaining aspect ratio
      let imgWidth = pdfWidth * 0.8; // Scale to 95% of the PDF width
      let imgHeight = imgWidth / aspectRatio;

      if (imgHeight > pdfHeight) {
        // If image height exceeds the page height after scaling, adjust by height
        imgHeight = pdfHeight * 0.95; // Scale to 95% of the PDF height
        imgWidth = imgHeight * aspectRatio;
      }

      // Calculate x-offset for horizontal centering
      const xOffset = (pdfWidth - imgWidth) / 2; // Center horizontally
      const yOffset = 0; // Align at the top

      pdf.addImage(dataUrl, "PNG", xOffset, yOffset, imgWidth, imgHeight);

      if (action === "download") {
        // Download the PDF
        pdf.save("document.pdf");
      } else if (action === "print") {
        // Open the PDF in a new window for printing
        const pdfDataUrl = pdf.output("bloburl");

        const printWindow = window.open(pdfDataUrl, "_blank");

        printWindow.onload = () => {
          printWindow.print(); // Trigger the print dialog
        };
      }

      // Reset the dimensions of the div
      documentContent.style.width = "";
      documentContent.style.height = "";
    });
  };

  return (
    <div>
      <div className="flex justify-center my-5 control-group">
        <button
          className="btn bg-secondary text-white border-0 mx-2"
          onClick={() => handleDownloadOrPrintDocument("download")}
        >
          ডাউনলোড
        </button>
        <button
          onClick={() => handleDownloadOrPrintDocument("print")}
          className="btn bg-red-500 text-white border-0 mx-2"
        >
          প্রিন্ট
        </button>
      </div>

      <div
        id="applicationPage"
        ref={printRef}
        className="mx-auto flex justify-center bg-white w-[794px] min-h-[1123px]"
      >
        <div>
          {/* Header Section */}
          <div className="relative">
            <div className="flex justify-center items-center py-8">
              <div className="text-center">
                <img
                  src={ecLogo}
                  alt="Gov Logo"
                  width={60}
                  height={60}
                  className="mx-auto my-3"
                />
                <h2 className="text-2xl font-bold text-gray-800">
                  জাতীয় পরিচিতি তথ্য
                </h2>
              </div>
            </div>
          </div>

          <div className=" w-full h-[2px] bg-green-600 top-0" />

          {/* Main Content */}
          <div className="flex gap-8 p-6">
            {/* Left - Photo */}
            <div className="flex-shrink-0 min-w-[20%]">
              <div className="flex justify-center">
                <img
                  src={photo}
                  alt="User"
                  className="w-32 h-32 object-contain"
                />
              </div>
              <p className="text-center text-xs mt-2 font-semibold">{nameEn}</p>
            </div>

            {/* Right - Details */}
            <div className="flex-1 max-w-[70%]">
              {/* NID Information */}
              <div className="mb-4">
                <div className="bg-green-700 text-white px-4 py-2 font-semibold text-md">
                  জাতীয় পরিচিতি তথ্য
                </div>
                <div className="border border-[#B5D5CA]">
                  <table className="w-full">
                    <tbody>
                      <tr className="border-b border-gray-200">
                        <td className="text-sm px-4 py-2 w-1/2">
                          জাতীয় পরিচয়পত্র নম্বর
                        </td>
                        <td className="text-sm px-4 py-2">{nationalId}</td>
                      </tr>
                      <tr>
                        <td className="text-sm px-4 py-2">Old NID Number</td>
                        <td className="text-sm px-4 py-2">{pin}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Personal Information */}
              <div className="mb-4">
                <div className="bg-green-700 text-white px-4 py-2 font-semibold text-md">
                  ব্যক্তিগত তথ্য
                </div>
                <div className="border border-[#B5D5CA]">
                  <table className="w-full">
                    <tbody>
                      <tr className="border-b border-gray-200">
                        <td className="text-sm px-4 py-2 w-1/2">নাম (বাংলা)</td>
                        <td className="text-sm px-4 py-2">{name}</td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="text-sm px-4 py-2">নাম (ইংরেজি)</td>
                        <td className="text-sm px-4 py-2">{nameEn}</td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="text-sm px-4 py-2">জন্ম তারিখ</td>
                        <td className="text-sm px-4 py-2">{dateOfBirth}</td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="text-sm px-4 py-2">পিতার নাম</td>
                        <td className="text-sm px-4 py-2">{father}</td>
                      </tr>
                      <tr className="border-b border-gray-200">
                        <td className="text-sm px-4 py-2">মাতার নাম</td>
                        <td className="text-sm px-4 py-2">{mother}</td>
                      </tr>
                      <tr>
                        <td className="text-sm px-4 py-2">
                          স্বামী/স্ত্রীর নাম
                        </td>
                        <td className="text-sm px-4 py-2">{spouse || "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Additional Information */}
              <div className="mb-4">
                <div className="bg-green-700 text-white px-4 py-2 font-semibold text-md">
                  অন্যান্য তথ্য
                </div>
                <div className="border border-[#B5D5CA]">
                  <table className="w-full">
                    <tbody>
                      <tr className="border-b border-gray-200">
                        <td className="text-sm px-4 py-2 w-1/2">লিঙ্গ</td>
                        <td className="text-sm px-4 py-2">{gender}</td>
                      </tr>
                      <tr>
                        <td className="text-sm px-4 py-2">পেশা</td>
                        <td className="text-sm px-4 py-2">{occupation}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              {/* Present Address */}
              <div className="mb-4">
                <div className="bg-green-700 text-white px-4 py-2 font-semibold text-md">
                  বর্তমান ঠিকানা
                </div>
                <div className="border border-[#B5D5CA] p-4 text-sm">
                  {presentAddress}
                </div>
              </div>

              {/* Permanent Address */}
              <div className="mb-4">
                <div className="bg-green-700 text-white px-4 py-2 font-semibold text-md">
                  স্থায়ী ঠিকানা
                </div>
                <div className="border border-[#B5D5CA] p-4 text-sm">
                  {permanentAddress}
                </div>
              </div>
            </div>
          </div>

          {/* Footer */}
          <div className="text-center py-4 text-sm">
            This is a Software Generated Report. Signature & Seal Aren't
            Required.
          </div>
        </div>
      </div>
    </div>
  );
}

export default NIDVerification;
