import React, { useState } from "react";
import api from "../../utils/axios.config"; // Axios instance with interceptors
import { FaCheck } from "react-icons/fa";
import { toast } from "react-hot-toast";
import NIDVerification from "./NIDVerification";

const dummyNIDData = {
  name: "মোঃ রফিকুল ইসলাম",
  nameEn: "Md Rafikul Islam",
  gender: "পুরুষ",
  father: "আব্দুল করিম",
  mother: "সালমা বেগম",
  spouse: "ফাতেমা খাতুন",
  nationalId: "1234567890",
  dateOfBirth: "1985-03-15",
  photo: "https://avatar.iran.liara.run/public/boy",
  pin: "19850315123456789",
  occupation: "ব্যবসায়ী",
  presentAddress:
    "বাসা/হোল্ডিং: ১২৩, গ্রাম/রাস্তা: আজিমপুর রোড, ডাকঘর: নিউমার্কেট-১২০৫, ঢাকা, বাংলাদেশ",
  permanentAddress:
    "বাসা/হোল্ডিং: ৪৫৬, গ্রাম/রাস্তা: শহীদ মির্জা লেন, ডাকঘর: খুলনা-৯০০০, খুলনা, বাংলাদেশ",
};

function Verify() {
  const [nidData, setNidData] = useState(null);
  const [nid, setNid] = useState("");
  const [dob, setDob] = useState("");
  const [loading, setLoading] = useState(false);

  // Handle NID Verification
  const handleVerify = async () => {
    if (!nid || !dob) {
      toast.error("Please enter both NID and Date of Birth");
      return;
    }

    setLoading(true);
    try {
      const response = await api.post("/user/verify-nid", {
        nid: nid,
        dob: dob,
      });

      if (response.data.success) {
        setNidData(response.data.data);
        toast.success("NID verified successfully!");
      } else {
        toast.error(response.data.message || "Verification failed.");
      }
    } catch (error) {
      console.error("Error verifying NID:", error);
      toast.error("Failed to fetch data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Input Section */}
      <div className="flex flex-wrap justify-center items-center gap-4 my-5 p-4 bg-gray-100 rounded-lg shadow">
        {/* NID Input */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Enter NID
          </label>
          <input
            type="text"
            placeholder="Enter NID"
            value={nid}
            onChange={(e) => setNid(e.target.value)}
            className="w-64 px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Date of Birth Input */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Date of Birth
          </label>
          <input
            type="date"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            className="w-64 px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        {/* Verify Button */}
        <button
          className="btn bg-secondary text-white border-0 mx-2 mt-5"
          onClick={handleVerify}
          disabled={loading}
        >
          {loading ? (
            "Verifying..."
          ) : (
            <>
              {" "}
              <FaCheck /> Verify{" "}
            </>
          )}
        </button>
      </div>

      {/* Result Section */}
      {nidData && (
        <div className="py-8 max-w-2xl mx-auto bg-white">
          <NIDVerification data={nidData} />
        </div>
      )}
    </>
  );
}

export default Verify;
